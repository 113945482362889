<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="courseData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="courseData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <course-view-course-info-card :course-data="courseData" />
        </b-col>
      </b-row>

      <h4>Course Lectures</h4>
      <lectures-list></lectures-list>
      <h4 style="margin-top: 20px">Course Flashcards</h4>
      <flashcards-list></flashcards-list>
      <h4 style="margin-top: 20px">Course Notes</h4>
      <notes-list></notes-list>
      <h4 style="margin-top: 20px">Course Questions</h4>
      <questions-list></questions-list>
      <h4 style="margin-top: 20px">Course Categories</h4>
      <categories-list></categories-list>
      <h4 style="margin-top: 20px">Course Sub Categories</h4>
      <sub-categories-list></sub-categories-list>
      <h4 style="margin-top: 20px">Add/Remove Course Images</h4>
      <course-images></course-images>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import UserCoursesList from './UserCoursesList.vue'
import courseStoreModule from '../courseStoreModule'
import CourseViewCourseInfoCard from './CourseViewCourseInfoCard.vue'
import UserViewUserDevicesCard from './UserViewUserDevicesCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'
import LecturesList from '../../lectures/lectures-list/CourseLecturesList'
import FlashcardsList from '../../flash-cards/flash-cards-list/CourseFlashCardsList'
import NotesList from '../../notes/notes-list/CourseNotesList'
import QuestionsList from '../../questions/questions-list/CourseQuestionsList'
import CategoriesList from '../courses-list/CoursesCategoriesList'
import SubCategoriesList from '../courses-list/CoursesSubCategoriesList'

export default {
  components: {
    BRow,
    LecturesList,
    FlashcardsList,
    QuestionsList,
    NotesList,
    CategoriesList,
    SubCategoriesList,
    BCol,
    BAlert,
    BLink,

    // Local Components
    CourseViewCourseInfoCard,
    UserViewUserDevicesCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,

    UserCoursesList,
  },
  setup() {
    const courseData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'courses'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('courses/fetchCourse', { id: router.currentRoute.params.id })
      .then(response => { courseData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          courseData.value = undefined
        }
      })

    return {
      courseData,
    }
  },
}
</script>

<style>

</style>
