<template>
  <div>
    <b-card v-for="(device,index) in userData.devices"
    no-body
    class="border-primary col-lg-6 col-sm-12" style="display: inline-block;margin-right: 1%;width: 49%"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        {{ device.device_name }}
      </h5>
      <small class="text-muted w-100">{{ device.created_at }}</small>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">Device ID : {{ device.device_id }}</span>
        </li>
        <li class="my-25">
          <span class="align-middle">Allow Notifications : </span>
          <b-badge v-if="device.allow_push_notifications == 1" variant="light-success">Enabled</b-badge>
          <b-badge v-else variant="light-danger">Disabled</b-badge>
        </li>
        <li class="my-25">
          <span class="align-middle">Is Tablet : </span>
          <b-badge v-if="device.is_tablet == 1" variant="light-success">Yes</b-badge>
          <b-badge v-else variant="light-warning">No</b-badge>
        </li>
        <li class="my-25">
          <span class="align-middle">Device Type : {{device.device_type}}</span>

        </li>
      </ul>
      <b-button
        @click="deleteDevice(device.id)"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >
        Delete Device
      </b-button>
    </b-card-body>
  </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods:{
    deleteDevice(id){
      axios
          .post(`/users/delete-device/${id}`)
          .then(response => {
            this.userData = response.data.data
            this.showToast('success', 'top-center', response.data.message)
          })
          .catch(error => reject(error))
    },
    showToast(variant, position, message) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: message,
              variant,
            },
          },
          {
            position,
          })
    }
  }
}
</script>

<style>
@media only screen and (max-width: 450px) {
  .border-primary {
    width: 100% !important;
  }
}
</style>
