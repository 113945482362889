<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="courseData.image"
            :text="avatarText(courseData.title_en)"
            :variant="`light-${resolveUserRoleVariant(courseData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ courseData.title_en }}
              </h4>
              <span class="card-text">{{ courseData.title_ar }}</span>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ courseData.price }}
              </h5>
              <h5>Courses Price</h5>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">English Title</span>
            </th>
            <td class="pb-50">
              {{ courseData.title_en }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Arabic Title</span>
            </th>
            <td class="pb-50">
              {{ courseData.title_ar }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge v-if="courseData.is_active" variant="light-success">Active</b-badge>
              <b-badge v-else variant="light-danger">Not Active</b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Rating</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-form-rating
                  style="padding-left: 0!important;"
                  id="rating-inline"
                  inline
                  readonly
                  no-border
                  variant="warning"
                  :value="courseData.rate"
              />
              <span>({{courseData.rate}})</span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Flashcards Count</span>
            </th>
            <td class="pb-50">
              {{ courseData.flashcards_count }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="BookIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Lectures</span>
            </th>
            <td>
              {{ courseData.lectures_count }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="BookOpenIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Notes Count</span>
            </th>
            <td>
              {{ courseData.notes_count }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="BookOpenIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Questions Count</span>
            </th>
            <td>
              {{ courseData.questions_count }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="BookOpenIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Speciality</span>
            </th>
            <td>
              {{ courseData.speciality }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,BBadge,BFormRating
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useCoursesList from '../courses-list/useCoursesList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,BBadge,BFormRating
  },
  props: {
    courseData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useCoursesList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
}
</script>

<style>

</style>
