import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCoursesList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'cover_image', sortable: false },
    { key: 'title_en', sortable: true },
    { key: 'title_ar', sortable: false },
    { key: 'admin_name', sortable: false },
    { key: 'speciality', sortable: false },
    { key: 'price', sortable: false },
    { key: 'price_after_discount', sortable: false },
    { key: 'actions' },
    { key: 'lectures_count', sortable: false },
    { key: 'flashcards_count', sortable: false },
    { key: 'notes_count', sortable: false },
    { key: 'questions_count', sortable: false },
    { key: 'rate', sortable: false },
    { key: 'is_active', sortable: false },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const specialityFilter = ref(null)
  const adminFilter = ref(null)
  const planFilter = ref(null)
  const verifiedFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, specialityFilter, adminFilter,planFilter, verifiedFilter, statusFilter], () => {
    refetchData()
  })

  const fetchCourses = (ctx, callback) => {
    store
      .dispatch('courses/fetchCourses', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        speciality: specialityFilter.value,
        admin_id: adminFilter.value,
        plan: planFilter.value,
        verified: verifiedFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { courses, total } = response.data

        callback(courses)
        totalUsers.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserIsActiveVariant = is_active => {
    if (is_active === 0) return 'warning'
    if (is_active === 1) return 'success'
    if (is_active === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveUserVerifiedVariant = verified => {
    if (verified === 'not verified') return 'warning'
    if (verified === 'verified') return 'success'
    if (verified === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchCourses,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserIsActiveVariant,
    resolveUserVerifiedVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    specialityFilter,
    adminFilter,
    planFilter,
    verifiedFilter,
    statusFilter,
  }
}
